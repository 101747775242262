@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-picker-time-panel-column {
    overflow-y: auto !important;
}

.ant-select-dropdown {
    overflow-y: auto !important;
}
.rc-virtual-list-holder {
    overflow: auto !important;
}
