.footer {
    @apply bg-neutral-200 text-center  lg:text-left;
}

.footerContainer {
    @apply p-4 text-center text-neutral-700;
}

.footerContainer .a {
    @apply text-neutral-800;
}

.layout {
    @apply flex flex-col h-screen justify-start
    bg-repeat bg-cover bg-center
    bg-[url("background.svg")]
}